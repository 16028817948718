/**
 * PLEASE DO NOT MODIFY THIS FILE!
 *
 * This is the main shop class of the Fef project. It will initialise ans enable the default shop
 * functionality depending on the default and project config files.
 * To alter the functionality, please modify the project config file.
 */


import {AbstractModule} from "../AbstractModule";
import {Filters} from "./Filters";

/**
 * Front-end Framework main shop class. This class contains the main shop functionality of the Fef.
 */
class Shop extends AbstractModule {

    constructor(callbacks) {
        super(callbacks, ['matchHeightShopArticles']);
    }

    init() {
        this.filters = null;
        if (this.config.modules.shop.hasOwnProperty('filtersEnabled') && (this.config.modules.shop.filtersEnabled === true)) {
            this.filters = new Filters(this.callbacks);
        }
    }
}

export {Shop}