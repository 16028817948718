/**
 * PLEASE DO NOT MODIFY THIS FILE!
 *
 * This is the main class of the Fef project. It will initialise ans enable the default functionality
 * depending on the default and project config files.
 * To alter the functionality, please modify the project config file.
 */

import configDefault from "../config/default";
import config from "../config/project";
import {Sliders} from "./Sliders";
import {Navigation} from "./Navigation";

// Modules
import {Shop} from "./shop/Shop";
import {AbstractModule} from "./AbstractModule";
import {FormElements} from "./FormElements";

/**
 * Front-end Framework main class. This class contains the main functionality of the Fef.
 */
class Fef extends AbstractModule {
    constructor(callbacks = []) {
        super(callbacks, ['matchHeightSite']);
    }

    init() {
        this.mergeWidth = null;
        this.shop = null;

        // Call basic functionality.
        this.header();
        this.setBackgroundImage();
        this.smoothScroll();
        this.toggle();
        this.merge();
        this.resizeCallbackTimer = null;

        // Optional functionality.
        if (this.config.searchToggle) {
            this.searchToggle();
        }
        if (this.config.googleRecaptcha) {
            this.googleRecaptcha();
        }

        // Sliders, Navigation
        new Sliders(this.config);
        new Navigation(this.config);

        window.addEventListener('resize', this.merge());

        // Basic resize events.
        window.addEventListener('resize', () => {
            /**
             * Remove possible callback timer.
             */
            if (this.resizeCallbackTimer) {
                clearTimeout(this.resizeCallbackTimer);
            }

            this.header();
            this.toggle();
            this.merge();

            /**
             * Make sure touch-enabled devices can view the drop-down menus
             * starting from a landscape mode (> config.breakpoints.sm)
             */
            if ($(window).outerWidth() > this.config.breakpoints.sm) {
                $('.nav .nav__item:has(ul)').doubleTapToGo();
            }

            /**
             * Call resize callback.
             */
            let that = this;
            this.resizeCallbackTimer = setTimeout(function () {
                that.callback('resize');
            }, 100);
        });

        /*
         * Enable the required modules.
         */
        if (this.config.modules.hasOwnProperty('shop')) {
            this.shop = new Shop(this.callbacks.hasOwnProperty('shop') ? this.callbacks['shop'] : []);
        }

        /*
         * Enable floating labels.
         */
        this.config.floatingLabels.forEach((selector) => {
            FormElements.floatingLabel(selector);
        });

        /**
         * As soon as this file is loaded, jQuery will remove the `no-jquery`
         * class from the `body`. This indicates that jQuery has been loaded.
         */
        $('body').removeClass('no-jquery');

        /**
         * As soon as this file is loaded, jQuery will remove the `u-jquery`
         * class from the hidden `divs`. This indicates that jQuery has been loaded.
         */
        $('.u-jquery').removeClass('u-jquery');
    };

    header() {
        let self = this;
        let scrollTop = 150;

        $(window).scroll(function () {
            if ($(window).scrollTop() < scrollTop) {
                $('.js-header').removeClass('header--small');
                $('body').removeClass('header--is-small');
            } else {
                $('.js-header').addClass('header--small');
                $('body').addClass('header--is-small');
            }
        });

        if ($(window).scrollTop() < scrollTop) {
            $('.js-header').removeClass('header--small');
            $('body').removeClass('header--is-small');
        } else {
            $('.js-header').addClass('header--small');
            $('body').addClass('header--is-small');
        }
    };

    /**
     * Replace `img` with `background-image` in parent `div`.
     */
    setBackgroundImage() {
        $('[data-set-background-image]').each(function (index) {
            if ($(this).find('img').length) {
                let image = $(this).find('img');
                let imageUrl = $(image).attr('src');

                $(this).find('*').hide();

                $(this).css({
                    'background-image': 'url(' + imageUrl + ')'
                });
            }
        });
    };

    /**
     * Make links to anchors scroll smoothly to the anchor.
     */
    smoothScroll() {
        $('a[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && (location.hostname === this.hostname)) {
                let target = $(this.hash);

                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 180
                    }, 1000);
                    return false;
                }
            }
        });

        if (location.hash.length > 1) {
            var target = $('[name=' + location.hash.substr(1) + ']');

            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 180
                }, 1000);
                return false;
            }
        }
    };

    /**
     * Enable default toggling by data attributes..
     */
    toggle() {
        $('.js-toggle-display').click(function (e) {
            e.preventDefault();

            let target = $(this).attr('data-toggle-display');
            let breakpoint = $(this).attr('data-toggle-display-breakpoint') ? $(this).attr('data-toggle-display-breakpoint') : 'md';

            if (target === undefined) {
                console.error('You are missing an element: data-toggle-display');
                console.warn('Please add the following to your HTML code: data-toggle-display');
            }

            if ($(window).outerWidth() < this.breakpoints[breakpoint]) {
                $(this).toggleClass('reverse');
                $(target).toggle().toggleClass('is-active');
            }
        });
    };

    searchToggle() {
        let self = this;

        $(".header .search button").click(function (e) {
            if ($(window).outerWidth() > self.config.breakpoints.md) {
                e.preventDefault();
                $('.header .search input[type=search]').focus();
            }

            let form = $(this).parent('form');
            let status = $(this).attr('data-active');
            let textHasBeenInserted = $(this).siblings('.box').find('.keywords').val().length;

            if (status === 'false') {
                $('.header .search').toggleClass("active");
                $('.header .searchbar').toggleClass("active");

                $(this).attr('data-active', 'true');
            } else if (textHasBeenInserted === 0) {
                $('.header .search').toggleClass("active");
                $('.header .searchbar').toggleClass("active");

                $(this).attr('data-active', 'false');
            } else {
                form.submit();
            }
        });
    };

    merge() {
        if (!this.mergeWidth || (this.mergeWidth !== $(window).width())) {
            if ($('nav.nav').length > 1) {
                console.error('There are too many "nav" HTML element with the "nav" class.');
                console.warn('Please only use one "nav" HTML element with the "nav" class.');
            }

            let self = this;
            $('.js-nav-merge[data-id][data-position][data-breakpoint][data-priority][data-move]').each(function () {
                let originalPosition = $(this).data('id'),
                    position = $(this).data('position'),
                    breakpoint = self.config.breakpoints[$(this).data('breakpoint')],
                    target = '.nav',
                    priority = $(this).data('priority'),
                    willMove = $(this).data('move');

                let elements = {
                    id: originalPosition,
                    position: position,
                    breakpoint: breakpoint,
                    target: target,
                    priority: priority,
                    move: willMove,
                };

                self.checkForErrorsIn(elements);

                if ($(window).outerWidth() < breakpoint) {
                    if (position === 'prepend') {
                        if ($(this).is('ul') || willMove) {
                            $(this).attr('data-priority', priority)
                                .addClass('is-moved')
                                .addClass('is-prepend')
                                .addClass(originalPosition)
                                .prependTo(target);
                        } else {
                            $(this).find('> ul')
                                .attr('data-priority', priority)
                                .addClass('is-moved')
                                .addClass('is-prepend')
                                .addClass(originalPosition)
                                .prependTo(target);
                        }
                    } else {
                        if ($(this).is('ul') || willMove) {
                            $(this).attr('data-priority', priority)
                                .addClass('is-moved')
                                .addClass('is-append')
                                .addClass(originalPosition)
                                .appendTo(target);
                        } else {
                            $(this).find('> ul')
                                .attr('data-priority', priority)
                                .addClass('is-moved')
                                .addClass('is-append')
                                .addClass(originalPosition)
                                .appendTo(target);
                        }
                    }
                } else {
                    $('.' + originalPosition).prependTo('#' + originalPosition)
                        .removeClass('is-moved')
                        .removeClass('is-append')
                        .removeClass('is-prepend')
                        .removeClass(originalPosition);
                }
            });

            $('.is-append').sort(function (a, b) {
                return (a.dataset.priority < b.dataset.priority) ? -1 : (a.dataset.priority > b.dataset.priority) ? 1 : 0;
            }).appendTo('.nav');

            $('.is-prepend').sort(function (a, b) {
                return (a.dataset.priority < b.dataset.priority) ? -1 : (a.dataset.priority > b.dataset.priority) ? 1 : 0;
            }).prependTo('.nav');

            this.mergeWidth = $(window).width();
        }
    };

    checkForErrorsIn(elements) {
        for (let key in elements) {
            if (elements.hasOwnProperty(key) && (elements[key] === undefined)) {
                console.error('You are missing an element: ' + key);
                console.warn('Please add the following to your HTML code: data-' + key);
            }
        }
    };

    googleRecaptcha() {
        if (document.getElementsByTagName('body')[0].hasAttribute('data-recaptcha-key')) {
            window.googleRecaptchaCallback = () => {
                let siteKey = document.getElementsByTagName('body')[0].getAttribute('data-recaptcha-key');
                Array.prototype.slice.call(document.querySelectorAll('.invisible-recaptcha')).forEach((element) => {
                    grecaptcha.render(element.getAttribute('id'), {
                        'sitekey': siteKey,
                        'callback': function (token) {
                            element.closest('form').querySelector('.g-recaptcha-response').value = token;
                            element.closest('form').submit();
                        }
                    });
                });
            };

            let scriptTag = document.createElement('script');
            scriptTag.src = `https://www.google.com/recaptcha/api.js?onload=googleRecaptchaCallback&render=explicit`;
            scriptTag.defer = true;
            document.getElementsByTagName('head')[0].insertBefore(scriptTag, null);
        }
    };
}

export {Fef}