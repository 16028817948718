import {AbstractModule} from "../AbstractModule";

class Filters extends AbstractModule {
    constructor(callbacks) {
        super(callbacks);

        this.request = null;

        this.init();
    }

    init() {
        this.filterDiv = $('.js-filter-form');
        this.filterForm = this.filterDiv.find('form');
        this.sortForm = null;

        this.initFilters();
    }

    initFilters() {
        let list = $('.filters__list'),
            items = $('.filters__item'),
            toggle = $('.js-filter-toggle'),
            toggleSearchClear = $('.js-search-clear'),
            toggleSearchSubmit = $('.js-search-submit');

        /**
         * Hide items bases on `data-filter-items` max.
         */
        function toggleFilterView() {
            list.each(function () {
                let items = $(this).data('filter-items');
                $(this).children('li').slice(0, items).show();
            });
        }

        toggleFilterView();

        /**
         * When clicking on the show more toggle,
         * this will make sure all items in that list are shown.
         * Clicking it again will hide all items, except the first few
         * (based on the `data-filters-items` attribute).
         */
        toggle.off('click').on('click', function () {
            let list = $(this).parent().find('.filters__list'),
                toggle = $(this).parent().find('.js-filter-toggle');
            list.toggleClass('is-open');
            if (list.hasClass('is-open')) {
                toggle.html(toggle.data('less'));
                toggle.addClass('is-open');
            } else {
                toggle.html(toggle.data('more'));
                toggle.removeClass('is-open');
            }
            list.each(function () {
                let items = $(this).data('filter-items');
                $(this).children('li').slice(items).slideToggle(150);
            });
        });
        /**
         * When toggling any of the items,
         * make sure they move to the list of active filters
         */
        let activeFilters = [];
        items.each(function () {
            $(this).click(function () {
                $(this).toggleClass('selected');
            });

            $(this).find('input').on('click', function () {
                if ($(this).is(':checked')) {
                    let item = $(this).attr('name');
                    activeFilters.push(item);
                    updateActiveFilters();
                    $(this).parents('.filters__item').addClass('selected');
                } else {
                    let item = $(this).attr('name');
                    activeFilters = $.grep(activeFilters, function (value) {
                        return value !== item;
                    });
                    updateActiveFilters();
                    $(this).parents('.filters__item').removeClass('selected');
                }
            });
        });

        function updateActiveFilters() {
            let form = $('.js-filter-form'),
                location = form.attr('action'),
                formData = form.serialize();
            if (formData !== '') {
                $.get(location, formData, function (data) {
                    $('.products').html(data);
                });
            }
        }

        /**
         * When searching for a particular filter,
         * filter down the list based on search term
         */
        $('.js-search-filters').keyup(function () {
            let filterListItems = $(this).parents('.filters__search').siblings('.filters__list').find('.filters__item'),
                toggle = $(this).parents('.filters__search').siblings('.js-filter-toggle'),
                searchValue = this.value.toLowerCase(),
                valueLength = this.value.length,
                toggleSearchSubmit = $(this).siblings('.js-search-submit'),
                toggleSearchClear = $(this).siblings('.js-search-clear');
            $(toggleSearchSubmit).hide();
            $(toggleSearchClear).show();
            (valueLength === 0) ? $(toggle).show() : $(toggle).hide();
            if ($(this).val() !== '') {
                $(filterListItems).each(function () {
                    let text = $.trim($(this).text()),
                        textLowerCase = text.toLowerCase();
                    if (textLowerCase.indexOf(searchValue) >= 0) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                $(toggleSearchSubmit).show();
                $(toggleSearchClear).hide();
                $(filterListItems).hide();
                toggleFilterView();
            }
        });
        $(toggleSearchClear).on('click', function (e) {
            e.preventDefault();
            let filterListItems = $(this).parents('.filters__search').siblings('.filters__list').find('.filters__item'),
                toggle = $(this).parents('.filters__search').siblings('.js-filter-toggle');
            $(this).parents('.filters__search').find('.js-search-filters').val('');
            $(filterListItems).hide();
            $(toggle).show();
            $(toggleSearchSubmit).show();
            $(toggleSearchClear).hide();
            toggleFilterView();
        });
    }
}

export {Filters}